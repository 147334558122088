import React, { useEffect, useState } from 'react'
import './index.scss'
import Header from '../Layout/Header';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../Layout/Footer';
import { useNavigate } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Antedu from './antedu';
import { checkUser } from '../../services/userService';
import { isDesktop} from "react-device-detect";
import HomeModal from '../ComponentShare/Modal/HomeModal';
import HomeMenu from '../Layout/Menu/Home';
import HomePageMb from './indexMb';
import HomeLight from './indexLight';
import PopupModal from '../ComponentShare/Modal/PopupModal';
import { useSearchParams } from 'react-router-dom';
import AiSpeakingModal from '../ComponentShare/Modal/AiSpeakingModal';

const HomePage = () => {
    const { t } = useTranslation();
    const  i18nextLng = localStorage.getItem("i18nextLng")?localStorage.getItem("i18nextLng"):"vi"
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [selecetTest, setSelecetTest] = useState(searchParams.get("test") == "speakingai" ? "/speakingai": "/shorttest");

    const handleClickTest = async (subSkill)=>{
        if(selecetTest === "/speakingai"){
            try {
                const result = await checkUser()
            } catch (error) {
                navigate("/auth/login")
                return
            }
            
        }
        localStorage.setItem("skill",selecetTest)
        localStorage.setItem("subSkill",subSkill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    
    useEffect(()=>{
        if(document.referrer) localStorage.setItem("referrer",document.referrer)
        const browserId = localStorage.getItem("browserId")
        if(!browserId){
            localStorage.setItem("browserId",uuidv4());
        }
    },[])

    // console.log("----searchParams",searchParams.get("test"))
    // console.log("----selecetTest",selecetTest)
    return (
        <>
            {/* <PopupModal /> */}
            {
                isDesktop ?
                    <>
                        {
                            darkMode =="dark" &&
                            <>
                                <div className='container-fluid homepage' id="homepage">             
                                    {
                                        searchParams.get("test")=="speakingai" ? 
                                        <AiSpeakingModal handleClickTest={handleClickTest} selecetTest={setSelecetTest}/>
                                        :
                                        <HomeModal handleClickTest={handleClickTest} selecetTest={setSelecetTest}/>
                                    }
                                    <HomeMenu selecetTest={setSelecetTest}/>
                                    <Header/>
                                    <section className='edtech '  id="edtech">
                                        <div className='container'>
                                            <div className='header-edtech d-flex align-items-center justify-content-center'>
                                                <h3 className=''>AI IN EDTECH</h3>
                                            </div>
                                            <div className='content-edtech'>
                                                <div className='row p-0 m-0'>
                                                    <div className='col-md-5 content-left'>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech1.title`)}}></p>
                                                                <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech1.detail`)}}></p>
                                                            </div>
                                                            <img src="./images/edtech-left.png" className="card-img-bottom" alt="edtech" />
                                                        </div>
                        
                                                    </div>
                                                    <div className='col-md-7 content-right'>
                                                        <div className="card  card1">
                                                            <div className="row g-0">                                        
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech2.title`)}}></p>
                                                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech2.detail`)}}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <img src="./images/edtech-right1.png" className="img-fluid" alt="edtech" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card  card2">
                                                            <div className="row g-0">                                        
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <p className="card-title h5" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech3.title`)}}></p>
                                                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: t(`home.edtech.edtech3.detail`)}}></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <img src="./images/edtech-right2.png" className="img-fluid" alt="edtech" />
                                                                </div>
                                                            </div>
                                                        </div>
                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='footer-edtech d-flex justify-content-center'>
                                                <img src="./images/arrow-down-exp.gif" width={54} className="card-img-bottom" alt="edtech" />
                                            </div>
                                        </div>                
                                    </section>            
                        
                                    <Antedu/>
                        
                                    <section className='testlist d-flex align-items-center justify-content-center '  id="testlist">
                                        <div className='container  p-0 m-0'>
                                            <div className='testlist-left' >
                                                <img src="./images/AI.png"  className="card-img-bottom" alt="testlist" />
                                            </div>
                                            <div className='testlist-right' >
                                                <div className='testlist-head'>
                                                    <h3 className='title' dangerouslySetInnerHTML={{ __html: t(`home.testlist.title`)}}></h3>
                                                </div>
                                                <div className='testlist-content'>
                                                    <ul className="nav nav-tabs"  role="tablist">
                                                        <li className="nav-item" role="presentation" onClick={()=>setSelecetTest("/shorttest")}>
                                                            <span className={`nav-link ${ selecetTest =="/shorttest" ? "active" :""}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#short" type="button" role="tab" aria-controls="short" aria-selected={`${ selecetTest =="/fulltest" ? "true" :"false"}`}>BASIC TEST</span>
                                                        </li>
                                                        <li className="nav-item" role="presentation" onClick={()=>setSelecetTest("/fulltest")}>
                                                            <span className={`nav-link ${ selecetTest =="/fulltest" ? "active" :""}`} id="full-tab" data-bs-toggle="tab" data-bs-target="#full" type="button" role="tab" aria-controls="full" aria-selected={`${ selecetTest =="/fulltest" ? "true" :"false"}`}>ADVANCE TEST</span>
                                                        </li>
                                                        <li className="nav-item" role="presentation" onClick={()=>setSelecetTest("/speakingai")}>
                                                            <span className={`nav-link ${ selecetTest =="/speakingai" ? "active" :""}`} id="speaking-tab" data-bs-toggle="tab" data-bs-target="#speaking" type="button" role="tab" aria-controls="speaking" aria-selected={`${ selecetTest =="/fulltest" ? "true" :"false"}`}>AI SPEAKING TEST</span>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div className={`tab-pane fade ${ selecetTest =="/shorttest" ? "show active" :""}`} id="short" role="tabpanel" aria-labelledby="home-tab">
                                                            <p className='detai'>{t(`home.testlist.quicktest.detai`)}</p>
                                                            <div className='list-item'>
                                                                <ul>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list"> {t(`home.testlist.quicktest.subjects.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.subjects.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    {/* <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.duration.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.duration.value`)}</div>
                                                                        </div>
                                                                    </li> */}
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.total.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.total.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.skills.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.skills.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.maximum.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.maximum.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.testformat.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.testformat.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.quicktest.target.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.quicktest.target.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane fade ${ selecetTest =="/fulltest" ? "show active" :""}`} id="full" role="tabpanel" aria-labelledby="full-tab">
                                                            <p className='detai'>{t(`home.testlist.fulltest.detai`)}</p>
                                                            <div className='list-item'>
                                                                <ul>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list"> {t(`home.testlist.fulltest.subjects.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.subjects.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    {/* <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.duration.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.duration.value`)}</div>
                                                                        </div>
                                                                    </li> */}
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.total.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.total.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.skills.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.skills.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.maximum.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.maximum.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.testformat.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.testformat.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.fulltest.target.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.fulltest.target.value`)}</div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className={`tab-pane fade ${ selecetTest =="/speakingai" ? "show active" :""}`} id="speaking" role="tabpanel" aria-labelledby="speaking-tab">
                                                            <p className='detai'>{t(`home.testlist.speakingtest.detai`)}</p>
                                                            <p className='detai'>{t(`home.testlist.speakingtest.guide`)}</p>
                                                            <div className='list-item'>
                                                                <ul>
                                                                
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.speakingtest.total.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.speakingtest.total.value`)}</div>
                                                                        </div>
                                                                    </li>    
                                                                    
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.speakingtest.duration.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.speakingtest.duration.value`)}</div>
                                                                        </div>
                                                                    </li>   
                                                                    <li>
                                                                        <div className="d-flex">
                                                                            <div className="me-auto main-list">{t(`home.testlist.speakingtest.maximum.title`)}</div>
                                                                            <div className="small-list">{t(`home.testlist.speakingtest.maximum.value`)}</div>
                                                                        </div>
                                                                    </li>                                    
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='testlist-footer'>
                                                    {
                                                        selecetTest == "/speakingai"? 
                                                        <>
                                                            <div className='btn-test-now' data-bs-toggle="modal" data-bs-target="#speakingModal">
                                                                <span>{t(`home.testlist.btnTest`)}</span>
                                                            </div>
                                                            <div className="modal fade" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className='row'>
                                                                            <div className='col-11 '>
                                                                                <h1 className="modal-title fs-5 text-center" id="speakingModalLabel" dangerouslySetInnerHTML={{ __html: t(`home.testlist.speakingtest.lable`)}}></h1>
                                                                            </div>
                                                                            <div className='col-1 p-0'>
                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col-6'>
                                                                                <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickTest("basic")}>{t(`home.testlist.speakingtest.selectSkill.basic`)}</button>
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickTest("advance")} >{t(`home.testlist.speakingtest.selectSkill.advance`)}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :                                   
                                                        <div className='btn-test-now' onClick={()=>handleClickTest("")}>
                                                            <span>{t(`home.testlist.btnTest`)}</span>                                            
                                                        </div>
                                                            
                                                        
                                                    }
                                                                            
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    
                                    <section className='ielts-list d-flex align-items-center justify-content-center'  id="ieltsList">
                                        <div className='container m-0 p-0'>
                                            <div className='ielts-list-left'>
                                                <div className='left-head'>
                                                    <h3 className='title'>{t(`home.ieltsList.title`)}</h3>                            
                                                </div>
                                                <div className='left-content'>
                                                    <div className='list-core'>
                                                        <div className='box-core'>
                                                            <p  className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#40">4.0+ IELTS</p>
                                                            <div id="40" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.40.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.40.b`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p  className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#55">5.5+ IELTS</p>
                                                            <div id="55" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.55.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.55.b`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.55.c`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p  className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#65">6.5+ IELTS</p>
                                                            <div id="65" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.65.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.b`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.c`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.65.d`)}</p>
                                                            </div>
                                                        </div>
                                                        <div className='box-core'>
                                                            <p  className='title-score toggle-collapse' data-bs-toggle="collapse" data-bs-target="#75">7.5+ IELTS</p>
                                                            <div id="75" className="collapse">
                                                                <p className='item-score'>{t(`home.ieltsList.75.a`)}</p>
                                                                <p className='item-score'>{t(`home.ieltsList.75.b`)}</p>
                                                            </div>
                                                        </div>
                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ielts-list-right'>
                                                <img src="./images/AI2.png"  className="card-img-bottom" alt="antedu" />
                                            </div>
                                        </div>
                                    </section>
                        
                        
                                    <section className='about-us '  id="aboutUs">
                                        <div className='container'>
                                            <div className='header-about-us'>
                                                <h3 className='text-center'>{t(`home.aboutUs.title`)}</h3>
                                            </div>
                                            <div className="container content-about-us">
                                                <div className="row mx-auto my-auto justify-content-center">
                                                    <Carousel 
                                                        responsive={responsive}
                                                        autoPlay={true}
                                                        autoPlaySpeed={5000}
                                                        itemclassName="image-item"
                                                    >
                                                        <div className="card">
                                                            <a href='https://therealielts.vn/ve-phuong-phap-hoc/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide1.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.technology`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide2.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.human`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://therealielts.vn/ve-phuong-phap-hoc/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide3.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.exclustive`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/san-pham/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide4.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.commitment`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide5.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.comnunity`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide6.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.product`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://ant-edu.vn/ve-ant-edu/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide7.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.event`)}</div>
                                                            </a>
                                                        </div>
                                                        <div className="card">
                                                            <a href='https://khaothi.ant-edu.ai/'>
                                                                <div className="card-img">
                                                                    <img src="./images/slide8.png" className="img-fluid" />
                                                                </div>
                                                                <div className="card-img-overlay d-flex align-items-end">{t(`home.aboutUs.exam`)}</div>
                                                            </a>
                                                        </div>
                                                    </Carousel>
                                                </div>		
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <Footer/>
                            </>
                        }
                        {
                            darkMode =="light" && <HomeLight />
                        }
                    </>
                :
                <HomePageMb />
            }
       </>
    )
}
export default HomePage